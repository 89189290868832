<template>
  <el-card>
    <div class="filter_container">
      <el-input
        style="width: 300px"
        v-model="searchWord"
        placeholder="输入关健词"
        clearable
        @change="handleSearchWord"
      >
        <template #prefix>
          <el-icon>
            <search />
          </el-icon>
        </template>
      </el-input>
      <el-button type="primary" @click="downloadLogFile" :disabled="disabled">
        下载日志
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      style="margin-bottom: 20px"
      :data="tableData"
      border
      :default-sort="{ prop: 'operateTime', order: 'descending' }"
      @sort-change="sortChange"
    >
      <el-table-column
        v-for="{ prop, label, width, sortable } in colData"
        :key="prop"
        :prop="prop"
        :label="label"
        :width="width"
        :sortable="sortable"
        :sort-orders="['ascending', 'descending']"
      />
    </el-table>
    <div style="text-align: right">
      <el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[10, 20, 50]"
        :total="totalSize"
        background
        layout="prev, pager, next, sizes"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script setup>
import { Search } from "@element-plus/icons-vue";
import { ref, computed } from "vue";
import { getLogAdminList, downloadLog } from "@/api/logAdmin";

const searchWord = ref("");
const currentPage = ref(1);
const pageSize = ref(10);
const totalSize = ref(0);
const orderName = ref("operateTime");
const ascDesc = ref("desc");
const loading = ref(true);
const disabled = ref(false);
const tableData = ref([]);
const colData = [
  { label: "用户名", prop: "userName", width: 120, sortable: false },
  { label: "用户IP", prop: "userIp", width: 180, sortable: false },
  { label: "模块", prop: "operatePath", width: 160, sortable: false },
  { label: "行为", prop: "operateItem", width: 160, sortable: false },
  { label: "具体操作", prop: "operateContent", width: 160, sortable: false },
  { label: "行为时间", prop: "operateTime", width: 200, sortable: "custom" },
  { label: "备注", prop: "memo", width: "", sortable: false },
];
const params = computed(() => {
  return {
    searchWord: searchWord.value,
    orderName: orderName.value,
    ascDesc: ascDesc.value,
    page: currentPage.value,
    pageSize: pageSize.value,
  };
});
const getTableList = async () => {
  loading.value = true;
  const {
    data: { logInfoCount, listLogInfo },
  } = await getLogAdminList(params.value);
  totalSize.value = logInfoCount;
  tableData.value = listLogInfo;
  loading.value = false;
};
getTableList();

const handleSearchWord = (val) => {
  searchWord.value = val.trim();
  currentPage.value = 1;
  getTableList();
};
const sortChange = ({ prop, order }) => {
  console.log(prop, order);
  orderName.value = prop;
  ascDesc.value = order.slice(0, -6);
  getTableList();
};
const handleSizeChange = () => {
  getTableList();
};
const handleCurrentChange = () => {
  getTableList();
};
const downloadLogFile = async () => {
  disabled.value = true;
  await downloadLog(searchWord.value);
  disabled.value = false;
};
</script>

<style scoped lang="scss">
.filter_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
