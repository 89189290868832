import request, { downloadFile } from "@/utils/axios";
import { timeStampToDate } from "@/utils/tools";

export function getLogAdminList(params) {
  return request.get("/logaudit/info", params);
}

export function downloadLog(searchWord) {
  const date = timeStampToDate();
  console.log(date);
  return downloadFile(
    "/logaudit/download",
    { searchWord },
    `操作日志数据_${date}.xlsx`
  );
}
